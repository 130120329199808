/* eslint-disable react/prop-types */
import React from "react";
import { Link, graphql } from "gatsby";

// Components
import Container from "~components/common/Container";
import Grid from "~components/common/Grid";
import PageHeader from "~components/sections/PageHeader";
import Layout from "~components/layouts/Layout";
import CardBlog from "~components/cards/CardBlog";

// Types
import type { SiteMetadata, Location } from "~types/Gatsby";
import type { Article } from "~types/Article";

interface DataProps {
	site: {
		siteMetadata: SiteMetadata;
	};
	allWpPost: {
		nodes: Article[];
	};
}

export default function Page({ data, location }: { data: DataProps; location: Location }) {
	const { nodes: articles }: { nodes: Article[] } = data.allWpPost;

	return (
		<Layout
			location={location}
			title="Blog"
			desc=""
			// imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			// imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			// imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			// imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
		>
			<PageHeader
				subtitle="Alle Beiträge"
				title="Blog"
				description="Hier erhälst regelmäßig Informationen zu unseren Themen."
			/>
			<Container>
				<Grid cols={3}>
					{articles.map((article) => (
						<CardBlog key={article.id} article={article} />
					))}
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	query ($skip: Int, $pageSize: Int) {
		site {
			siteMetadata {
				siteUrl
			}
		}

		allWpPost(sort: { fields: date, order: DESC }, skip: $skip, limit: $pageSize) {
			nodes {
				dateGmt(formatString: "DD.MM.YYYY")
				title
				uri
				excerpt
				id
				featuredImage {
					node {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(aspectRatio: 1.5)
							}
						}
					}
				}
			}
		}
	}
`;
