// Packages
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import parse from "html-react-parser";

// Components
import Button from "~components/common/Button";
import H3 from "~components/typography/H3";

interface Props {
	article: {
		title: string;
		excerpt: string;
		dateGmt: string;
		uri: string;
		featuredImage: {
			node: {
				altText: string;
				localFile: IGatsbyImageData;
			};
		};
	};
}

const CardBlog: React.FC<Props> = ({ article }) => {
	const { title, excerpt, dateGmt, uri, featuredImage } = article;
	const image = featuredImage?.node?.localFile ? getImage(featuredImage?.node?.localFile) : undefined;
	const to = `/blog${uri}`;
	const alt = `Blogbeitrag: ${title}`;

	return (
		<div>
			{image ? (
				<Link to={to}>
					<GatsbyImage image={image} className="mb-6 rounded" alt={alt} title={alt} />
				</Link>
			) : null}
			<H3 as="p" className="mb-4">
				{title}
			</H3>
			<div className="mb-2">{parse(excerpt)}</div>
			<time dateTime={dateGmt} className="mb-4 block text-xs">
				{dateGmt}
			</time>
			<Button text="Weiterlesen" type="secondary" to={to} className="px-0" />
		</div>
	);
};

export default CardBlog;
