import React, { isValidElement } from "react";

// Components
import Container from "~components/common/Container";
import H1 from "~components/typography/H1";
import P from "~components/typography/P";
import SubTitle from "~components/typography/SubTitle";

interface Props {
	subtitle?: string;
	title: string;
	description?: string | React.ReactNode;
}

const PageHeader: React.FC<Props> = ({ subtitle, title, description }) => {
	return (
		<Container backgroundColor="bg-primary-100" noMargin className="py-8 text-center md:py-16">
			{subtitle ? <SubTitle className="mb-2">{subtitle}</SubTitle> : null}
			<H1 display className="mb-4">
				{title}
			</H1>
			{description && isValidElement(description) ? description : <P>{description}</P>}
		</Container>
	);
};

export default PageHeader;
